<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <a href="#"> <i class="uil-home-alt"></i> </a>
            </li>
            <li>
              <a>{{ $t("user_group.users") }}</a>
            </li>
            <li>{{ $t("user_group.title") }}</li>
            <li>{{ $t("user_group.edit") }}</li>
          </ul>
        </nav>
      </div>

      <div class="card">
        <div v-for="error in formErrors" v-if="formErrors" class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </div>
        <div v-if="successMessage" class="uk-alert-success" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: green">
            <i class="uil-exclamation-triangle"></i>
            {{ $t("general.update_success") }}
          </p>
        </div>
      </div>

      <div uk-grid>
        <div class="uk-width-1-4@m">
          <!--uk-flex-last@m-->
          <nav class="responsive-tab style-3 setting-menu card"
            uk-sticky="top:30 ; offset:100; media:@m ;bottom:true; animation: uk-animation-slide-top">
            <ul class="uk-tab-left uk-tab"
              uk-switcher="connect: #user-group-tab ; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
              <li class="uk-active">
                <a href="#">{{ $t("user_group.information") }}</a>
              </li>
              <li>
                <a href="#">{{ $t("user_group.group_user") }}</a>
              </li>
            </ul>
          </nav>
        </div>
        <div class="uk-width-expand">
          <ul class="uk-switcher uk-margin" id="user-group-tab">
            <li>
              <div class="card rounded">
                <div class="p-3 d-flex justify-content-between">
                  <h4 class="mb-0">{{ $t("user_group.information") }}</h4>
                </div>
                <hr class="m-0" />
                <div class="course-card-body" v-if="loading">
                  <default-loading></default-loading>
                </div>
                <div v-else class="course-card-body">
                  <div class="uk-grid-collapse uk-child-width-1-1@s" uk-grid>
                    <div class="position-relative">
                      <div class="form-group row">
                        <div class="col-md-12">
                          <label class="col-form-label" for="name">{{ $t("user_group.list.name")
                            }}<span class="required">*</span></label>
                          <input type="text" class="form-control" id="name" name="name" placeholder=""
                            v-if="formattedItem" v-model="form.name" required="" />
                        </div>
                      </div>
                      <div class="form-group row mt-3 input-checkbox">
                        <div class="col-md-12">
                          <div class="d-flex">
                            <input id="status" type="checkbox" v-model="form.status" />
                            <label class="ml-2" for="status">{{
                              $t("user_group.list.active")
                            }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="card rounded">
                <div class="p-3 d-flex justify-content-between align-items-center">
                  <h4 class="mb-0">{{ $t("user_group.group_user") }}</h4>

                  <div class="uk-card-header p-0">
                    <div class="float-right">
                      <a href="#modal-overflow" uk-toggle type="button" class="uk-button uk-button-primary small"><i
                          class="uil-user-plus"></i>
                        {{ $t("user_group.add_user_group") }}</a>
                    </div>
                  </div>
                </div>
                <hr class="m-0" />
                <div class="course-card-body">
                  <!-- Card header -->
                  <div class="card-header actions-toolbar border-0">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex justify-content-between">
                        <a href="#" class="btn btn-icon btn-hover btn-circle">
                          <i class="uil-search"></i>
                        </a>
                        <div>
                          <form class="uk-search uk-search-navbar uk-width-1-1">
                            <input v-model="filter.search" :placeholder="$t('general.search')" autofocus=""
                              class="uk-search-input shadow-0 uk-form-small" type="search" />
                          </form>
                        </div>
                      </div>
                      <div class="d-flex">
                        <!-- Shorting -->
                        <a href="#" class="btn btn-icon btn-hover btn-circle" :uk-tooltip="$t('general.sort_list')">
                          <i class="uil-list-ui-alt"></i>
                        </a>

                        <div id="sortDropDown" class="uk-dropdown"
                          uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up">
                          <ul class="uk-nav uk-dropdown-nav">
                            <li class="uk-active">
                              <a href="#" @click.prevent="sortOrder('asc', 'name')">{{ $t("sorting.a_z") }}</a>
                            </li>
                            <li>
                              <a href="#" @click.prevent="sortOrder('desc', 'name')">{{ $t("sorting.z_a") }}</a>
                            </li>
                            <li>
                              <a href="javascript:void(0)" @click.prevent="sortOrder('asc', 'created_at')">
                                {{ $t("sorting.created_at_old") }}</a>
                            </li>
                            <li>
                              <a href="javascript:void(0)" @click.prevent="sortOrder('desc', 'created_at')">
                                {{ $t("sorting.created_at_new") }}</a>
                            </li>
                          </ul>
                        </div>

                        <!-- Filter -->
                        <a class="btn btn-icon btn-hover btn-circle" href="javascript:void(0)"
                          :uk-tooltip="$t('general.filter_list')">
                          <i class="uil-filter"></i>
                        </a>
                        <UserFilter id="userFilterGroup" @getFilter="getFilter($event)"></UserFilter>
                      </div>
                    </div>
                  </div>
                  <!-- Table -->
                  <div class="" v-if="loading">
                    <default-loading></default-loading>
                  </div>
                  <div v-else class="table-responsive">
                    <table class="table align-items-center">
                      <thead>
                        <tr>
                          <th scope="col">
                            {{ $t("user_group.list.first_lastname") }}
                          </th>
                          <th scope="col">
                            {{ $t("user_group.list.user_title") }}
                          </th>
                          <th scope="col">
                            {{ $t("user_group.list.added_date") }}
                          </th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody class="list">
                        <template v-for="(item, index) in formattedItem">
                          <tr>
                            <th scope="row">
                              <div class="media align-items-center">
                                <div class="avatar-parent-child" style="width: max-content">
                                  <img :src="item.photo_link" :alt="item.fullname" class="avatar rounded-circle" />
                                  <span :class="item.is_online
                                    ? 'bg-success'
                                    : 'bg-offline'
                                    " class="avatar-child avatar-badge">
                                  </span>
                                </div>

                                <div class="media-body ml-4">
                                  <a href="#" class="name h6 mb-0 text-sm">{{
                                    item.fullname
                                  }}</a>
                                  <small class="d-block font-weight-bold">#{{ item.id }}</small>
                                </div>
                              </div>
                            </th>
                            <td>{{ item.title }}</td>
                            <td>{{ item.created_at }}</td>
                            <td class="text-right">
                              <!-- Actions -->
                              <div class="actions ml-3">
                                <a class="btn btn-icon btn-hover btn-sm btn-circle" uk-toggle="target: #modal-profile"
                                  uk-tooltip="Kullanıcının Profili" @click="activeItem = item">
                                  <i class="uil-user"></i>
                                </a>
                                <a @click="removeUserGroup(item.id)" class="btn btn-icon btn-hover btn-sm btn-circle"
                                  uk-tooltip="Kullanıcıyı Gruptan Çıkar" title="" aria-expanded="false">
                                  <i class="uil-minus-circle text-danger"></i>
                                </a>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="uk-modal-footer uk-text-right bggray">
        <button @click="$router.push({ name: 'company.user.group' })"
          class="uk-button uk-button-default bg-white small mr-3">
          {{ $t("general.back") }}
        </button>
        <button @click="saveForm()" class="uk-button uk-button-primary small" type="button">
          {{ $t("general.save") }}
        </button>
      </div>
    </div>

    <AddToGroupModal :existing_users="existingUsers" @getList="getList($event)"></AddToGroupModal>

    <UserProfile v-if="formattedUserDetail && activeItem" :item="activeItem" :user_detail="formattedUserDetail">
    </UserProfile>
  </div>
</template>

<script>
import store from "@/core/services";
import moment from "moment";
import Pagination from "@/assets/components/Pagination";
import AddToGroupModal from "@/view/company/User/Group/AddToGroupModal";
import module, {
  BASE_URL,
  GET_ITEM,
  ITEM,
  MODULE_NAME,
  ERROR,
  UPDATE_ITEM,
  LOADING,
} from "@/core/services/store/user_group.module";
import $ from "jquery";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import UserFilter from "@/view/company/User/Filter";
import UserProfile from "@/view/company/User/UserProfile";
import company_module, {
  ONLINE_USERS,
  MODULE_NAME as COMPANY_MODULE,
} from "@/core/services/store/company.module";
import user_detail_module, {
  GET_ITEM as GET_USER_DETAIL,
  ITEM as USER_DETAIL,
  MODULE_NAME as USER_DETAIL_MODULE,
} from "@/core/services/store/user_detail.module";

const _COMPANY_MODULE = COMPANY_MODULE;
const _USER_DETAIL_MODULE = USER_DETAIL_MODULE;

export default {
  name: "UserGroupAdd",
  components: {
    Pagination,
    AddToGroupModal,
    DefaultLoading,
    UserFilter,
    UserProfile,
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(MODULE_NAME, module);
    registerStoreModule(_COMPANY_MODULE, company_module);
    registerStoreModule(_USER_DETAIL_MODULE, user_detail_module);
  },
  data() {
    return {
      activeItem: null,
      form: {
        name: "",
        status: true,
        userGroup: [],
      },
      filter: {
        search: null,
        order: "desc",
        sort: "id",
      },
      formErrors: [],
      successMessage: false,
    };
  },
  computed: {
    user_detail: {
      get() {
        return store.getters[USER_DETAIL_MODULE + "/" + USER_DETAIL];
      },
      set(value) { },
    },
    formattedUserDetail() {
      if (this.user_detail === null) return null;
      return this.user_detail;
    },
    existingUsers() {
      if (this.form && this.form.userGroup) {
        return _.map(this.form.userGroup, "id");
      }
      return [];
    },
    onlineUsers() {
      return store.getters[_COMPANY_MODULE + "/" + ONLINE_USERS];
    },
    item: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEM];
      },
      set(value) { },
    },
    errors: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
    loading() {
      return store.getters[MODULE_NAME + "/" + LOADING];
    },
    formattedItem() {

      let self = this;
      if (self.item === null || !("users" in self.item)) {
        return null;
      }
      let results = this.fixUserGroup('format');
      // self.form = self.item;



      // self.form.userGroup = results;

      return results;
    },
  },
  methods: {
    getUserDetail(id) {
      store.dispatch(USER_DETAIL_MODULE + "/" + GET_USER_DETAIL, {
        url: "api/user/detail-admin" + "/" + id,
      });
    },
    getFilter(value) {
      console.log(value);
      this.filterResult(value);
    },

    fixUserGroup(event = null) {
      let results = [];

      this.item.users.forEach((item) => {
        results.push({
          id: item.id,
          name: item.name,
          surname: item.surname,
          dob: item.dob ? item.dob : "-",
          email: item.email,
          phone: item.phone,
          status_id: item.status_id,
          is_online: this.onlineUsers.find(
            (onlineUser) => onlineUser.user == item.id && onlineUser.status
          )
            ? true
            : false,
          photo_link: item.photo_link,
          fullname: [item.name, item.surname].join(" "),
          title: item.title ? item.title.name : "-",
          created_at: item?.pivot?.created_at
            ? moment(item?.pivot?.created_at).format("DD/MM/YYYY HH:mm:ss")
            : "-",
          last_login_at: item.last_login_at
            ? moment(item.last_login_at).format("DD/MM/YYYY HH:mm:ss")
            : "-",
        });
      });
      if (!event) {
        this.form.userGroup = results;
      }
      return results;
    },
    saveForm() {
      let self = this;
      let form = self.form;

      self.formErrors = [];
      if (form.name.length == 0) {
        self.formErrors.push(self.$t("user_group.required.name"));
        self.scrollToTop();
        return false;
      }
      let ids = [];
      form.userGroup.forEach((item) => {
        ids.push(item.id);
      });
      form.user_ids = ids;
      store
        .dispatch(MODULE_NAME + "/" + UPDATE_ITEM, {
          url: BASE_URL + "/" + this.$route.params.id,
          contents: form,
          returnType: "stateless",
          acceptPromise: true,
        })
        .then(() => {
          if (!this.error) {
            $("html, body").animate({ scrollTop: 0 }, "slow");
            this.successMessage = true;
            self.$router.push({ name: "company.user.group" });
          }
        });
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    getList(value) {
      value.forEach((item) => {
        this.item.users.push(item);
        this.form.userGroup.push(item);
      });
    },
    removeUserGroup(index) {
      const deletedUserGroupIndex = this.item.users.findIndex(item => item.id === index);
      const deletedUserIndex = this.form.userGroup.findIndex(item => item.id === index)

      this.item.users.splice(deletedUserGroupIndex, 1);
      this.form.userGroup.splice(deletedUserIndex, 1);
    },

    sortOrder(order, sort) {
      this.page = 1;
      this.filter.order = order;
      this.filter.sort = sort;
      this.filterResult();
    },

    filterResult(filter = null) {
      let self = this;
      UIkit.dropdown("#userFilterGroup").hide();
      UIkit.dropdown("#sortDropDown").hide();
      let filters = {
        like: self.filter.search,
        page: self.page,
        per_page: self.per_page,
        sort: self.filter.sort,
        order: self.filter.order
      };
      if (filter) {
        filter.company_id ? filters['company_id'] = filter.company_id : null;
        filter.company_id = null;
        $.each(filter, function (key, value) {
          if (value && Array.isArray(filter[key])) {
            let ids = [];
            filter[key]?.forEach((items) => {
              ids.push(items.id);
            });
            filters[key] = JSON.stringify(ids)
          }
        });
      }
      store.dispatch(MODULE_NAME + "/" + GET_ITEM, {
        url: BASE_URL + "/" + this.$route.params.id,
        filters: filters,
      });
    },
    setModalListener() {
      let self = this;
      $("#modal-overflow").on({
        'show.uk.modal': function () {
          UIkit.dropdown("#userFilterGroup").$destroy(true);
        },
        "hide.uk.modal": function () {
          UIkit.dropdown("#userFilterGroup").$destroy(true);
        },
      });
    },
  },
  mounted() {
    let filters = {
      like: this.filter.search,
      page: this.page,
      per_page: this.per_page,
      sort: this.filter.sort,
      order: this.filter.order
    };
    store.dispatch(MODULE_NAME + "/" + GET_ITEM, {
      url: BASE_URL + "/" + this.$route.params.id,
      filters: filters,
    }).then(data => {
      this.form = data;
      this.fixUserGroup();
    });
  },
  watch: {
    "filter.search": function (val) {
      this.filterResult();
      this.debouncedFilterSearch = _.debounce(this.filterResult, 500)
    },
    activeItem(value) {
      if (value) {
        this.getUserDetail(value.id);
      }
    },
  },
  beforeDestroy() {
    const modal = UIkit.modal("#modal-profile");
    if (modal) modal.$destroy(true);
  },
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}
</style>
<style scoped>
.uk-alert {
  margin: 0;
}

.no-padding {
  padding: 0;
}
</style>
